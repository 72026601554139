<template>
    <img :id="id" v-if="src !== null && !error" :src="src" @click="$emit('click')" @load="loaded">
    <div v-else-if="error">
        <slot></slot>
    </div>
</template>

<script>
import authHeader from '@/services/auth-header';
import crypto from 'crypto';
export default {
    name: "SecureImage",
    props: {
        filename: String,
        storage_account: String,
        site_id: String,
        client_id: String,
        id: String,
        endpoint: {
            type: String,
            required: false,
        },
        magnify: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            src: null,
            error: false
        }
    },
    mounted() {

        let API_ENDPOINT = '/api/event/thumbnail/';
        if(this.endpoint !== undefined){
            if(this.endpoint.length > 0){
                API_ENDPOINT = this.endpoint;
            }
        }

        this.loadImage();

    },
    methods: {
          loadImage(){
              let API_ENDPOINT = '/api/event/thumbnail/';
              if(this.endpoint !== undefined){
                  if(this.endpoint.length > 0){
                      API_ENDPOINT = this.endpoint;
                  }
              }

              axios.get(`https://api.varsanpr.com${API_ENDPOINT}${this.filename}?site_id=${this.site_id}&client_id=${this.client_id}&storage_account=${this.storage_account || 1}`, {
                  responseType: 'arraybuffer',
                  headers: authHeader()
              })
                  .then(response => {
                    if(this.src?.length > 0){
                        window.URL.revokeObjectURL(this.src);
                        this.src = null;
                    }
                      this.src = `data:image/jpeg;base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
                  })
                  .catch(error => {
                      this.$error(`Failed to download image ${this.filename}`, error);
                      this.error = true;
                  });
          },
          loaded(){
            this.$emit('load');
            if(this.magnify){
                this.magnifyImage(this.id, 2);
            }
          },
          magnifyImage(imgID, zoom) {
            //https://www.w3schools.com/howto/howto_js_image_magnifier_glass.asp
            var img, glass, w, h, bw;
            img = document.getElementById(imgID);
            /*create magnifier glass:*/
            glass = document.createElement("DIV");
            glass.setAttribute("class", "img-magnifier-glass");
            /*insert magnifier glass:*/
            img.parentElement.insertBefore(glass, img);
            /*set background properties for the magnifier glass:*/
            glass.style.backgroundImage = "url('" + img.src + "')";
            glass.style.backgroundRepeat = "no-repeat";
            glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
            bw = 3;
            w = glass.offsetWidth / 2;
            h = glass.offsetHeight / 2;
            /*execute a function when someone moves the magnifier glass over the image:*/
            glass.addEventListener("mousemove", moveMagnifier);
            img.addEventListener("mousemove", moveMagnifier);
            /*and also for touch screens:*/
            glass.addEventListener("touchmove", moveMagnifier);
            img.addEventListener("touchmove", moveMagnifier);
            function moveMagnifier(e) {
                var pos, x, y;
                /*prevent any other actions that may occur when moving over the image*/
                e.preventDefault();
                /*get the cursor's x and y positions:*/
                pos = getCursorPos(e);
                x = pos.x;
                y = pos.y;
                glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
                /*prevent the magnifier glass from being positioned outside the image:*/
                if (x > img.width - (w / zoom)) {x = img.width - (w / zoom);}
                if (x < w / zoom) {x = w / zoom;}
                if (y > img.height - (h / zoom)) {y = img.height - (h / zoom);}
                if (y < h / zoom) {y = h / zoom;}
                /*set the position of the magnifier glass:*/
                glass.style.left = (x - w) + "px";
                glass.style.top = (y - h) + "px";
                /*display what the magnifier glass "sees":*/
                glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
            }
            function getCursorPos(e) {
                var a, x = 0, y = 0;
                e = e || window.event;
                /*get the x and y positions of the image:*/
                a = img.getBoundingClientRect();
                /*calculate the cursor's x and y coordinates, relative to the image:*/
                x = e.pageX - a.left;
                y = e.pageY - a.top;
                /*consider any page scrolling:*/
                x = x - window.pageXOffset;
                y = y - window.pageYOffset;
                return {x : x, y : y};
            }
        }
    },
    watch: {
        filename: function(val){
            this.loadImage();
        },
        site_id: function(val){
            this.loadImage();
        },
        endpoint: function(val){
            this.loadImage();
        }
    }
}
</script>

<style scoped>
    .missing-image{
        width: 250px;
        height: 250px;
        background-color: #8e8c8c;
    }

    .animated-background {
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background-color: #8e8c8c;
        background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
        background-size: 800px 104px;
        position: relative;
    }

    @keyframes placeHolderShimmer {
        0% {
          background-position: -800px 0
        }
        100% {
          background-position: 800px 0
        }
    }

    * {box-sizing: border-box;}

    .img-magnifier-container {
    position: relative;
    }

    .img-magnifier-glass {
    position: absolute;
    border: 3px solid #000;
    border-radius: 50%;
    cursor: none;
    /*Set the size of the magnifier glass:*/
    width: 100px;
    height: 100px;
    }
</style>
